import React, { useEffect } from "react";
import { ProductItemInterface, VariantItem } from "../../../assets/interfaces";
import { toCurrency } from "../../../assets/js/utils/functions";
import { checkItemAvailability } from "../../../assets/js/utils/utils";
import RoundCheckbox from "../../ui/form-elements/round-checkbox";
import { FormatCurrencyFun } from "../../../contexts/cart-context";

interface Props {
  options: VariantItem[];
  selectedImages: string[];
  extraOption: string;
  selected: string[];
  setSelected: (options: string[]) => void;
  formatAsCurrency: FormatCurrencyFun;
  product: ProductItemInterface;
}

const SelectVariantExtra: React.FC<Props> = ({
  options,
  selectedImages,
  extraOption,
  selected,
  setSelected,
  formatAsCurrency,
  product,
}) => {
  const selectedWithOptions = selectedImages.map((s) => {
    return {
      image: s,
      options: options
        .filter((o) => o.image === s && checkItemAvailability(product, o, true))
        .map((o) => ({ option: o.values[extraOption], price: o.price, id: o.id })),
    };
  });

  useEffect(() => {
    if (selected.length > 0) {
      const prevSelectedImages = selected.map((s) => ({ [s]: options.find((o) => o.id === s)?.image }));

      //create a new selected array which is an array of the ids that match with previously selected images
      const newSelected = prevSelectedImages.filter((s) => {
        const image = Object.values(s)[0];

        return selectedImages.includes(image);
      });

      setSelected(newSelected.map((n) => Object.keys(n)[0]));
    }
  }, []);

  const selectOption = (option: string) => {
    const selectedCopy = [...selected];
    const optionIndex = selectedCopy.indexOf(option);

    if (optionIndex > -1) {
      selectedCopy.splice(optionIndex, 1);
      setSelected(selectedCopy);
    } else {
      setSelected([...selectedCopy, option]);
    }
  };

  return (
    <div className="mt-5">
      <span className="text-black-secondary text-1xs sm:text-sm mb-3 font-medium block">
        Please pick at least one {extraOption} for each image:
      </span>
      <ul className="space-y-7">
        {selectedWithOptions.map((s, index) => (
          <li className="border border-grey-divider rounded-10 relative p-2.5 !pb-1.5" key={index}>
            <figure className="h-12 w-12 rounded-5 overflow-hidden mb-2">
              <img src={s.image} alt="" className="h-full w-full object-cover" />
            </figure>

            <div className="flex flex-wrap items-start">
              {s.options.map((o, index) => (
                <div
                  className="inline-flex bg-grey-light px-2 py-1 rounded-5 items-center my-1.5"
                  key={index}
                  onClick={() => selectOption(o.id)}
                >
                  <RoundCheckbox
                    name={`variant-option`}
                    id={o.id}
                    onChange={() => selectOption(o.id)}
                    checked={selected.includes(o.id)}
                    small
                  />
                  <span className={`text-1xs inline-block ml-1.5 text-black-secondary font-medium`}>
                    {o.option} - {formatAsCurrency(o.price)}
                  </span>
                </div>
              ))}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectVariantExtra;
