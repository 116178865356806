import React from "react";

// interface CheckboxProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
//   className?: string;
//   name: string;
//   label?: string;
//   neutral?: boolean;
//   onChange?: (value: any) => void;
// }

// const Checkbox: React.FC<CheckboxProps> = ({ className, neutral, name, label, id, ...props }) => {
//   return (
//     <div className={`flex items-center ${className}`}>
//       <input type="checkbox" id={id} name={name} value="yes" className="hidden" {...props} />
//       <label htmlFor={id} className="select-none text-1xs flex items-center cursor-pointer">
//         <div
//           className={`rounded-[4px] border-2 ${
//             neutral ? "border-grey-smoke bg-grey-bg" : "border-accent-green-500"
//           } w-4 h-4 flex flex-shrink-0 justify-center items-center focus-within:border-accent-green-500 bg-white`}
//         >
//           <svg className="fill-current hidden w-2 h-2 text-accent-green-500 pointer-events-none" viewBox="0 0 17 12">
//             <g fill="none" fillRule="evenodd">
//               <g transform="translate(-9 -11)" fill="currentColor" fillRule="nonzero">
//                 <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
//               </g>
//             </g>
//           </svg>
//         </div>
//         {label && (
//           <span
//             className={`inline-block mt-1 ml-1.5 ${
//               name === "set-order-as-fulfilled" ? "text-sm text-dark" : "text-grey-subtext"
//             } `}
//           >
//             {label}
//           </span>
//         )}
//       </label>
//     </div>
//   );
// };

import classNames from "classnames";

interface CheckboxProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string;
  name: string;
  label?: string;
  neutral?: boolean;
  onChange?: (value: any) => void;
  round?: boolean;
  small?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({ className, small, neutral, name, label, id, round, ...props }) => {
  if (round) {
    return (
      <div className={`inline-flex ${className || ""}`}>
        <input type="radio" {...props} className="hidden" />
        <label
          htmlFor={name}
          className="inline-flex items-center text-sm cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            props.onChange(props.value);
          }}
        >
          <div
            className={classNames("relative", {
              "h-5 w-5": !small,
              "h-4 w-4": small,
            })}
          >
            <div
              className={classNames(
                "h-full w-full rounded-full !border-grey-border transition-all ease-out duration-200 absolute top-0 left-0 transform",
                {
                  border: small,
                  "border-2": !small,
                  "scale-1 opacity-1": !props.checked,
                  "opacity-0 scale-0 pointer-events-none": props.checked,
                }
              )}
            ></div>
            {/* prettier-ignore */}
            <svg 
              width="100%" 
              viewBox="0 0 18 18" 
              fill="none" 
              className={classNames(
                "h-full w-full transition-all ease-out duration-200 absolute top-0 left-0 transform text-success",
                {
                  "scale-1 opacity-1": props.checked,
                  "opacity-0 scale-0 pointer-events-none": !props.checked,
                }
              )}
            >
              <rect width="18" height="18" rx="9" fill="currentColor"/>
              <path d="M7.72136 12.375C7.54127 12.375 7.37019 12.2997 7.24412 12.1678L4.69585 9.50266C4.43472 9.22955 4.43472 8.77751 4.69585 8.5044C4.95698 8.2313 5.38919 8.2313 5.65033 8.5044L7.72136 10.6704L12.3497 5.82983C12.6108 5.55672 13.043 5.55672 13.3042 5.82983C13.5653 6.10294 13.5653 6.55498 13.3042 6.82809L8.1986 12.1678C8.07254 12.2997 7.90145 12.375 7.72136 12.375Z" fill="white"/>
            </svg>
          </div>
        </label>
      </div>
    );
  }

  return (
    <div className={`flex items-center ${className}`}>
      <input type="checkbox" id={id} name={name} value="yes" className="hidden" {...props} />
      <label htmlFor={id} className="select-none text-1xs flex items-center cursor-pointer">
        <div
          className={`rounded-[4px] border-2 ${
            neutral ? "border-grey-smoke bg-grey-bg" : "border-success"
          } w-4 h-4 flex flex-shrink-0 justify-center items-center focus-within:border-success bg-white`}
        >
          <svg className="fill-current hidden w-2 h-2 text-success pointer-events-none" viewBox="0 0 17 12">
            <g fill="none" fillRule="evenodd">
              <g transform="translate(-9 -11)" fill="currentColor" fillRule="nonzero">
                <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
              </g>
            </g>
          </svg>
        </div>
        {label && (
          <span
            className={`inline-block ml-1.5 ${
              name === "set-order-as-fulfilled" ? "text-sm text-dark" : "text-grey-subtext"
            } `}
          >
            {label}
          </span>
        )}
      </label>
    </div>
  );
};

export default Checkbox;

// export default Checkbox;
