import classNames from "classnames";
import Radio from "./form-elements/radio";

interface Props {
  title: string;
  icon: React.ReactElement;
  description: string;
  value: string;
  chosen: string;
  className?: string;
  showDetails?: boolean;
  onChange: () => void;
  name: string;
  disabled?: boolean;
}
export const CardWithDetails: React.FC<Props> = ({
  children,
  onChange,
  value,
  chosen,
  title,
  icon,
  description,
  className,
  showDetails = true,
  name,
  disabled,
}) => {
  const isSelected = value === chosen;
  return (
    <div
      onClick={() => {
        if (disabled) {
          return;
        }
        onChange();
      }}
      className={classNames(
        "flex items-start cursor-pointer p-4.5 sm:p-5 bg-grey-fields-100 w-full",
        {
          "opacity-60": disabled,
        },
        className
      )}
    >
      <div className="flex-shrink-0 mr-3 sm:mr-3.75">{icon}</div>
      <div className="w-full">
        <div className="flex justify-between items-start">
          <div>
            <h3 className="font-display text-base sm:text-lg font-bold text-black -mb-1">{title} </h3>
            <span className="text-xs sm:text-1xs text-black-muted">{description} </span>
          </div>
          <Radio name={name} value={value} chosen={chosen} onChange={onChange} disabled={disabled}></Radio>
        </div>
        {children && (
          <div
            onClick={(e) => e.stopPropagation()}
            className={`border-t overflow-hidden duration-200 ease-out border-grey-border border-opacity-50 ${
              isSelected && showDetails ? "pt-3.75 mt-3.75" : "h-0 border-none"
            }`}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};
