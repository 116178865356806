import * as Yup from 'yup'

const phoneValidation = Yup.object().shape({
  code: Yup.string().required("Country code is required"),
  digits: Yup.string()
    .required("Phone number is required")
    .test("minlen", "Phone number must be at least 7 digits", (val) => val !== undefined && val.length >= 7)
    .test("maxlen", "Phone number must be at most 15 digits", (val) => val !== undefined && val.length <= 15)
    .test("digits", "Phone number should contain only digits", (value) => /^\d+$/.test(value)),
});
const optionalPhoneValidation = Yup.object().shape({
  code: Yup.string(),
  digits: Yup.string()
    .notRequired()
    .test("minlen", "Phone number must be at least 7 digits", (val) => val == undefined || val.length >= 7)
    .test("maxlen", "Phone number must be at most 15 digits", (val) => val == undefined || val.length <= 15)
    .test("digits", "Phone number should contain only digits", (val) => val == undefined || /^\d+$/.test(val)),
});

export {
  phoneValidation,
  optionalPhoneValidation
}