import classNames from "classnames";
import { FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { DeliveryArea, DELIVERY_METHODS, StoreInterface, CouponItemInterface } from "../../../assets/interfaces";
import { getFieldvalues, toCurrency } from "../../../assets/js/utils/functions";
import { AppBtn } from "../../ui/buttons";
import DataAccordion from "../../ui/data-accordion";
import ErrorLabel from "../../ui/error-label";
import { InputField, PhoneInput, TextArea } from "../../ui/form-elements";
import SelectDropdownWithModal from "../../ui/form-elements/select-dropdown-with-modal";
import { ModalBody, ModalFooter } from "../../ui/modal";
import { CheckoutForm, DEFAULT_ADDRESS_CACHE } from "../modals/checkout";
import CartContext, { FormatCurrencyFun } from "../../../contexts/cart-context";
import InputFieldWithAutocomplete, { InputOptions } from "@/components/ui/form-elements/input-field-with-autocomplete";
import AddressAutocompleteInput from "./address-autocomplete-input";
import Portal from "@/components/portal";
import { ApplyCouponModal } from "./apply-coupon-modal";
import { useModals } from "@/components/hooks/useModals";
import { toast } from "@/components/ui/toast";
import { computePaymentCouponDiscount } from "@/assets/js/utils/utils";
import PreviousAddressModal from "./previous-address-modal";
import { useLocalObject } from "@/components/hooks/useLocalState";

interface Props {
  store: StoreInterface;
  prevStep: VoidFunction;
  nextStep: VoidFunction;
  scrollToTop?: boolean;
  form: FormikProps<CheckoutForm>;
  createOrderReq: any;
  formatAsCurrency: FormatCurrencyFun;
}

export interface AddressCache {
  validated: string[];
  userInputed: string[];
}

const CustomerDetailsStep: React.FC<Props> = ({
  store,
  prevStep,
  scrollToTop,
  form,
  createOrderReq,
  formatAsCurrency,
  nextStep,
}) => {
  const { priceUnformatted, currencies } = CartContext.useContainer();
  const [errorText, setErrorText] = useState("");
  const { modals, toggleModal } = useModals(["coupon", "address"]);
  const [coupon, setCoupon] = useState<{ couponCode: string; discountAmount: number }>(null);
  let modalBodyRef = useRef<HTMLDivElement>(null);
  const couponDataCache = useRef<CouponItemInterface>(null);

  const deliveryAreas = store.delivery_areas as DeliveryArea[];
  const { deliveringToSelf, deliveryMethod } = form.values;
  const hasCustomCheckoutForm = store?.configuration?.custom_checkout_form?.length > 0;

  useEffect(() => {
    form.setFieldValue("coupon", coupon?.couponCode ?? null);
  }, [coupon]);

  useEffect(() => {
    if (couponDataCache.current && priceUnformatted > 0) {
      applyCoupon(couponDataCache.current);
    }
  }, [priceUnformatted, couponDataCache]);

  useEffect(() => {
    scrollToTop && modalBodyRef.current.scrollTo(0, 0);
  }, [scrollToTop]);

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (deliveringToSelf === undefined) {
      setErrorText("Please provide your delivery information");
      return;
    }

    form.handleSubmit(e);
  };

  const changeDeliveryMethod = (method: DELIVERY_METHODS) => {
    if (!deliveringToSelf && method === DELIVERY_METHODS.PICKUP) {
      form.setFieldValue("deliveringToSelf", true);
    }

    form.setFieldValue("deliveryMethod", method);
  };

  const applyCoupon = (paymentCoupon: CouponItemInterface) => {
    // const total = priceUnformatted;
    couponDataCache.current = paymentCoupon;
    const discountAmount = computePaymentCouponDiscount(priceUnformatted, paymentCoupon);
    setCoupon({ couponCode: paymentCoupon.coupon_code, discountAmount });
    toast.success({
      title: "Coupon successfully applied",
      message: `Coupon (${paymentCoupon.coupon_code}) has been sucessfully added`,
    });

    toggleModal("coupon");
  };

  const handlePreviousAddress = (address: string) => {
    form.setFieldValue("address", address);
    toggleModal("address");
  };

  return (
    <>
      <form className="flex flex-col flex-auto overflow-hidden" onSubmit={submitForm}>
        <ModalBody setRef={(el) => (modalBodyRef.current = el)} className="relative">
          <ErrorLabel error={createOrderReq?.error?.message ?? errorText} setError={setErrorText} />
          {coupon?.couponCode && (
            <div className="bg-white sticky top-0 z-[1000] -mx-5 sm:-mx-6.25 transform -translate-y-5 sm:-translate-y-6.25 shadow-card !mb-0">
              <div className="py-2.5 text-black-secondary bg-accent-yellow-500 bg-opacity-20 px-4 text-1xs sm:text-sm flex items-start sm:items-center justify-center">
                {/* prettier-ignore */}
                <svg width="20px" viewBox="0 0 24 24" fill="none" className="transition-all ease-out duration-200 text-success mr-2.5 flex-shrink-0">
                  <path d="M21.5289 10.8689L20.0089 9.34891C19.7489 9.08891 19.5389 8.57891 19.5389 8.21891V6.05891C19.5389 5.17891 18.8189 4.45891 17.9389 4.45891H15.7889C15.4289 4.45891 14.9189 4.24891 14.6589 3.98891L13.1389 2.46891C12.5189 1.84891 11.4989 1.84891 10.8789 2.46891L9.33891 3.98891C9.08891 4.24891 8.57891 4.45891 8.20891 4.45891H6.05891C5.17891 4.45891 4.45891 5.17891 4.45891 6.05891V8.20891C4.45891 8.56891 4.24891 9.07891 3.98891 9.33891L2.46891 10.8589C1.84891 11.4789 1.84891 12.4989 2.46891 13.1189L3.98891 14.6389C4.24891 14.8989 4.45891 15.4089 4.45891 15.7689V17.9189C4.45891 18.7989 5.17891 19.5189 6.05891 19.5189H8.20891C8.56891 19.5189 9.07891 19.7289 9.33891 19.9889L10.8589 21.5089C11.4789 22.1289 12.4989 22.1289 13.1189 21.5089L14.6389 19.9889C14.8989 19.7289 15.4089 19.5189 15.7689 19.5189H17.9189C18.7989 19.5189 19.5189 18.7989 19.5189 17.9189V15.7689C19.5189 15.4089 19.7289 14.8989 19.9889 14.6389L21.5089 13.1189C22.1589 12.5089 22.1589 11.4889 21.5289 10.8689ZM7.99891 8.99891C7.99891 8.44891 8.44891 7.99891 8.99891 7.99891C9.54891 7.99891 9.99891 8.44891 9.99891 8.99891C9.99891 9.54891 9.55891 9.99891 8.99891 9.99891C8.44891 9.99891 7.99891 9.54891 7.99891 8.99891ZM9.52891 15.5289C9.37891 15.6789 9.18891 15.7489 8.99891 15.7489C8.80891 15.7489 8.61891 15.6789 8.46891 15.5289C8.17891 15.2389 8.17891 14.7589 8.46891 14.4689L14.4689 8.46891C14.7589 8.17891 15.2389 8.17891 15.5289 8.46891C15.8189 8.75891 15.8189 9.23891 15.5289 9.52891L9.52891 15.5289ZM14.9989 15.9989C14.4389 15.9989 13.9889 15.5489 13.9889 14.9989C13.9889 14.4489 14.4389 13.9989 14.9889 13.9989C15.5389 13.9989 15.9889 14.4489 15.9889 14.9989C15.9889 15.5489 15.5489 15.9989 14.9989 15.9989Z" fill="currentColor"/>
                </svg>
                <div className="flex items-center flex-wrap">
                  <div className="mr-3">
                    <b className="font-semibold">{coupon?.couponCode} Applied</b>
                    &nbsp;- {formatAsCurrency(Math.abs(coupon?.discountAmount))} OFF
                  </div>
                  <button
                    className="bg-white rounded-5 shadow-card text-xs text-accent-red-500 px-2 py-1 font-medium"
                    onClick={() => setCoupon(null)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          )}
          {store?.configuration?.customer_pickup_enabled && store?.configuration?.require_delivery_info && (
            <div>
              <div className="mb-3.75">
                <h4 className="font-display font-semibold text-black-secondary rounded-5 block">
                  How will you like to get your order?
                </h4>
              </div>

              <div className="flex items-center space-x-2.5">
                <DeliveryMethodBtn
                  onClick={(e) => changeDeliveryMethod(DELIVERY_METHODS.DELIVERY)}
                  active={deliveryMethod === DELIVERY_METHODS.DELIVERY}
                  label="Delivery"
                />

                <DeliveryMethodBtn
                  onClick={(e) => changeDeliveryMethod(DELIVERY_METHODS.PICKUP)}
                  active={deliveryMethod === DELIVERY_METHODS.PICKUP}
                  label="Pickup"
                />
              </div>
            </div>
          )}
          {deliveryMethod === DELIVERY_METHODS.DELIVERY && (
            <div>
              <div className="mb-3.75 mt-5">
                <h4 className="font-display font-semibold text-black-secondary rounded-5 block">
                  Who will receive this item?
                </h4>
              </div>

              <div className="flex items-center gap-3.75">
                <TabButton
                  label="Myself"
                  onClick={(e) => form.setFieldValue("deliveringToSelf", true)}
                  active={deliveringToSelf === true}
                />

                <TabButton
                  label="Someone Else"
                  onClick={(e) => form.setFieldValue("deliveringToSelf", false)}
                  active={deliveringToSelf === false}
                />
              </div>
            </div>
          )}

          <div className="mt-[27.5px]">
            {deliveringToSelf !== undefined && (
              <DeliveryDetailsForm
                {...{
                  deliveryiesEnabled: store.deliveries_enabled,
                  deliveryAreas,
                  form,
                  deliveringToSelf,
                  formatAsCurrency,
                  toggleModal,
                  emailRequired: store.configuration?.require_emails,
                }}
              />
            )}
          </div>
          <div>
            <DataAccordion title="Do you have a coupon?">
              <AppBtn size="lg" isBlock color="neutral" onClick={() => toggleModal("coupon")} className="mt-3.75">
                {form.values.coupon ? "Change Coupon" : "Apply Coupon"}
                <svg viewBox="0 0 15 16" fill="none" className="w-3.75 ml-px mt-px">
                  <path
                    d="M3.96484 11.5355L11.0359 4.46446"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M11.0352 11.5355L11.0352 4.46446L3.96409 4.46445"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </AppBtn>
            </DataAccordion>
          </div>

          <div>
            <DataAccordion title="Do you have any notes?">
              <TextArea className="!mt-5" label="Order Notes" rows={3} {...getFieldvalues("orderNotes", form)} />
            </DataAccordion>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="flex gap-2.5 w-full">
            <AppBtn onClick={prevStep} className="flex-1" color="neutral" size="lg">
              Go Back
            </AppBtn>
            <AppBtn className="flex-1" isBlock type="submit" disabled={createOrderReq.isLoading} size="lg">
              {createOrderReq.isLoading ? "Placing Order..." : "Place Order"}
            </AppBtn>
          </div>
        </ModalFooter>
      </form>
      <Portal>
        <ApplyCouponModal
          toggle={() => toggleModal("coupon")}
          applyCoupon={applyCoupon}
          store={store.id}
          show={modals.coupon.show}
          itemsAmount={priceUnformatted}
          currency={currencies.active}
          customerId={form?.values?.customer_id}
        />
        <PreviousAddressModal
          toggle={() => toggleModal("address")}
          onSelect={handlePreviousAddress}
          show={modals.address.show}
          address={form.values.address}
          deliveriesEnabled={store?.deliveries_enabled}
        />
      </Portal>
    </>
  );
};

interface DeliveryFormProps {
  form: FormikProps<CheckoutForm>;
  deliveringToSelf: boolean;
  deliveryiesEnabled: boolean;
  deliveryAreas: DeliveryArea[];
  formatAsCurrency: FormatCurrencyFun;
  toggleModal: (modal: string) => void;
  emailRequired?: boolean;
}

export const DeliveryDetailsForm: React.FC<DeliveryFormProps> = ({
  form,
  deliveringToSelf,
  deliveryAreas,
  deliveryiesEnabled,
  formatAsCurrency,
  toggleModal,
  emailRequired = false,
}) => {
  const [addresses] = useLocalObject<AddressCache>("addresses", DEFAULT_ADDRESS_CACHE);
  const customerIsPickingUp = form.values.deliveryMethod === DELIVERY_METHODS.PICKUP;
  const deliveryNotRequired = form.values.deliveryMethod === DELIVERY_METHODS.NONE;

  const addressListToUse = addresses[deliveryiesEnabled ? "validated" : "userInputed"];

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <DataAccordion title="Your Details">
        <div className="pt-5 !space-y-6">
          <InputField label="Full Name" {...getFieldvalues("name", form)} />
          <PhoneInput label="Your Whatsapp Number" {...getFieldvalues("phone", form)} />
          <InputField
            label={`Email Address ${!emailRequired ? "(optional)" : ""}`}
            required={emailRequired}
            type="email"
            {...getFieldvalues("email", form)}
          />
        </div>
      </DataAccordion>
      {!customerIsPickingUp && !deliveryNotRequired && (
        <DataAccordion title={deliveringToSelf ? "Delivery Details" : "Receiver's Details"}>
          <div className="pt-5">
            {!deliveringToSelf && (
              <>
                <InputField label="Receiver's Full Name" {...getFieldvalues("deliveryContact.name", form)} />
                <PhoneInput label="Receiver's Whatsapp Number" {...getFieldvalues("deliveryContact.phone", form)} />
              </>
            )}
            {deliveryAreas.length > 0 && (
              <SelectDropdownWithModal
                options={optionsFromDA(deliveryAreas, formatAsCurrency)}
                label="Select Delivery Area"
                hasSearch
                searchLabel="Search areas"
                {...getFieldvalues("area", form)}
              />
            )}
            {
              <TextArea className="!mt-5" label="Delivery Address" rows={3} {...getFieldvalues("address", form)} />
            }

            {addressListToUse && addressListToUse?.length > 0 && (
              <button
                onClick={() => toggleModal("address")}
                className="flex ml-auto items-center justify-center font-medium whitespace-nowrap bg-white text-primary-500 mt-2.5 text-1xs sm:text-sm rounded-10 undefined font-action !outline-none transition-all cursor-pointer box-border group flex-shrink-0"
                type="button"
              >
                Select From Previous Address
                {/* prettier-ignore */}
                <svg viewBox="0 0 15 16" fill="none" className="w-3.75 ml-px mt-px">
                  <path d="M3.96484 11.5355L11.0359 4.46446" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M11.0352 11.5355L11.0352 4.46446L3.96409 4.46445" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
              </button>
            )}
          </div>
        </DataAccordion>
      )}
    </div>
  );
};

interface IProps {
  label: string;
  active: boolean;
  onClick: (e: React.MouseEvent) => void;
}

const TabButton: React.FC<IProps> = ({ label, active, onClick }) => {
  return (
    <button
      className={classNames(
        "flex items-center transition-all ease-out duration-300 border rounded-[45px] font-medium text-1xs sm:text-sm px-4 py-2.5 sm:px-5 sm:py-3",
        {
          "bg-grey-fields-100 border-grey-border border-opacity-50 text-dark": !active,
          "bg-primary-500 border-primary-500 text-white": active,
        }
      )}
      type="button"
      onClick={onClick}
    >
      {label}
      {/* prettier-ignore */}
      <svg className={classNames("w-3.5 sm:w-3.75 transition-all ease-out duration-300 transform origin-center text-primary-500", {
        "opacity-1 scale-100 max-w-[100px] ml-1.25": active,
        "scale-0 pointer-events-none opacity-0 max-w-0": !active
      })} viewBox="0 0 14 15" fill="none">
        <rect y="0.5" width="14" height="14" rx="7" fill="white" />
        <path d="M6.0055 10.125C5.86543 10.125 5.73237 10.0664 5.63432 9.96386L3.65233 7.89096C3.44922 7.67854 3.44922 7.32695 3.65233 7.11454C3.85543 6.90212 4.1916 6.90212 4.3947 7.11454L6.0055 8.79922L9.6053 5.03431C9.8084 4.8219 10.1446 4.8219 10.3477 5.03431C10.5508 5.24673 10.5508 5.59832 10.3477 5.81073L6.37669 9.96386C6.27864 10.0664 6.14557 10.125 6.0055 10.125Z" fill="currentColor" />
      </svg>
    </button>
  );
};

interface BtnProps {
  label: string;
  active: boolean;
  onClick: (e: React.MouseEvent) => void;
}

export const DeliveryMethodBtn: React.FC<BtnProps> = ({ label, active, onClick, children }) => {
  return (
    <button
      className={classNames(
        "flex items-center justify-between border rounded-30 px-3 py-2 cursor-pointer no-outline transition-all ease-out duration-150",
        {
          "border-primary-500 bg-white": active,
          "border-grey-border border-opacity-50 bg-grey-fields-100 bg-opacity-30": !active,
        }
      )}
      onClick={onClick}
      type="button"
    >
      {children}
      <span className="text-sm text-black-secondary font-medium">{label}</span>
    </button>
  );
};

export const optionsFromDA = (dAs: DeliveryArea[], formatAsCurrency: FormatCurrencyFun) => {
  return dAs.map((d) => ({ text: `${d.name} - ${formatAsCurrency(d.fee)}`, value: d.id }));
};

export default CustomerDetailsStep;
