import useFluxState from "@/components/hooks/useFluxState";
import { useLocalObject } from "@/components/hooks/useLocalState";
import { AppBtn } from "@/components/ui/buttons";
import ErrorLabel from "@/components/ui/error-label";
import Modal, { ModalBody, ModalFooter } from "@/components/ui/modal";
import classNames from "classnames";
import error from "next/error";
import { useState } from "react";
import { AddressCache } from "./customer-details";
import { DEFAULT_ADDRESS_CACHE } from "../modals/checkout";

interface Props {
  show: boolean;
  toggle: VoidFunction;
  onSelect: (address: string) => void;
  address?: string;
  deliveriesEnabled: boolean;
}
const PreviousAddressModal: React.FC<Props> = ({ show, toggle, onSelect, address, deliveriesEnabled }) => {
  const [addresses] = useLocalObject<AddressCache>("addresses", DEFAULT_ADDRESS_CACHE);
  const [selectedAddress, setSelectedAddress] = useFluxState<string>(address);

  const addressKey = deliveriesEnabled ? "validated" : "userInputed";

  const handleRangeSelect = (option: string) => {
    setSelectedAddress(option);
  };
  const isSelected = (address) => address === selectedAddress;

  return (
    <>
      <Modal size="sm" show={show} toggle={toggle} title="Previous Addresses">
        <ModalBody>
          <ul className="flex flex-col divide-y divide-grey-divider">
            {addresses[addressKey]?.map((option, index) => (
              <li
                className="py-3 last:pb-0 first:pt-0 flex items-center justify-between text-dark text-sm cursor-pointer hover:bg-grey-light hover:bg-opacity-60 text-left w-full overflow-hidden"
                onClick={() => handleRangeSelect(option)}
                key={index}
              >
                <div className="flex-1 overflow-hidden mr-6">
                  <span className="block max-w-full overflow-ellipsis whitespace-nowrap overflow-hidden">{option}</span>
                  {/* <span className="text-xxs text-black-secondary font-medium">{isSelected(option)} </span> */}
                </div>
                <>
                  {/* prettier-ignore */}
                  <svg width="18px" viewBox="0 0 18 18" fill="none" 
                    className={classNames(
                      "transition-all ease-out duration-200 transform text-accent-green-500",
                      {
                        "scale-1 opacity-1": isSelected(option),
                        "opacity-0 scale-0 pointer-events-none": !isSelected(option),
                      }
                  )}>
                    <rect width="18" height="18" rx="9" fill="currentColor"/>
                    <path d="M7.72136 12.375C7.54127 12.375 7.37019 12.2997 7.24412 12.1678L4.69585 9.50266C4.43472 9.22955 4.43472 8.77751 4.69585 8.5044C4.95698 8.2313 5.38919 8.2313 5.65033 8.5044L7.72136 10.6704L12.3497 5.82983C12.6108 5.55672 13.043 5.55672 13.3042 5.82983C13.5653 6.10294 13.5653 6.55498 13.3042 6.82809L8.1986 12.1678C8.07254 12.2997 7.90145 12.375 7.72136 12.375Z" fill="white"/>
                  </svg>
                </>
              </li>
            ))}
            {!Boolean(addresses) && (
              <span className="text-black-muted text-sm text-center">You have no previous addresses</span>
            )}
          </ul>
        </ModalBody>
        <ModalFooter>
          <AppBtn disabled={!Boolean(selectedAddress)} onClick={() => onSelect(selectedAddress)} isBlock size="lg">
            Continue
          </AppBtn>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default PreviousAddressModal;
