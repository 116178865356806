import { GetStoreBySlugParams } from "./interfaces/stores.interface";
import { request } from "./utils";

const GetStoreBySlug = (data: GetStoreBySlugParams) => {
  return request(`stores/public/${data.slug}`, "get");
};

const GetStoreBankAccount = (data: { id: string }) => {
  return request(`stores/${data.id}/bank-account`, "get");
};

export { GetStoreBySlug, GetStoreBankAccount };
