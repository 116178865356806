const Table: React.FC<{ className?: string }> = ({ children, className = "" }) => {
  return (
    <div className={`overflow-auto w-full`}>
      <table className={`min-w-full overflow-hidden app-table ${className}`}>{children}</table>
    </div>
  );
};

const TableHead: React.FC<{ className?: string }> = ({ children, className = "" }) => {
  return (
    <thead className={`bg-grey-fields-100 ${className}`}>
      <tr>{children}</tr>
    </thead>
  );
};

const TableHeadItem: React.FC<
  React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement> & {
    className?: string;
  }
> = ({ children, className = "", ...props }) => {
  return (
    <th
      scope="col"
      className={`py-4 sm:py-5 px-3 sm:px-5 first:pl-5 last:pr-5 sm:first:pl-7.5 sm:last:pr-7.5 text-left text-xs font-semibold uppercase text-black-placeholder text-opacity-90 tracking-wider whitespace-nowrap first:rounded-l-8 last:rounded-r-8 ${className}`}
      {...props}
    >
      <span>{children}</span>
    </th>
  );
};

const TableBody: React.FC<{ className?: string }> = ({ children, className }) => {
  return (
    <tbody className={`divide-y divide-grey-border divide-opacity-50 overflow-hidden ${className}`}>{children}</tbody>
  );
};

const TableRow: React.FC<
  React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement> & { className?: string }
> = ({ children, className = "", ...props }) => {
  return (
    <tr className={`hover:bg-grey-fields-100 hover:bg-opacity-40 cursor-pointer group ${className}`} {...props}>
      {children}
    </tr>
  );
};

const TableCell: React.FC<
  React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement> & {
    className?: string;
    stopBubble?: boolean;
  }
> = ({ children, className = "", onClick, stopBubble, ...props }) => {
  const handleClick = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
    if (stopBubble) {
      e.stopPropagation();
    }

    onClick && onClick(e);
  };

  return (
    <td
      scope="col"
      className={`py-3 sm:py-3.75 px-3 sm:px-5 first:pl-5 last:pr-5 sm:first:pl-7.5 sm:last:pr-7.5 whitespace-nowrap text-sm text-dark tracking-1 ${className}`}
      onClick={handleClick}
      {...props}
    >
      {children}
    </td>
  );
};

const TableFooter: React.FC<{ className?: string; colSpan?: number }> = ({ children, className, colSpan }) => {
  return (
    <tfoot>
      <tr>
        <td className={`py-5 ${className}`} colSpan={colSpan || 6}>
          {children}
        </td>
      </tr>
    </tfoot>
  );
};

export default Table;

export { TableHead, TableHeadItem, TableRow, TableCell, TableFooter, TableBody };
