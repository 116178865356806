import { OrderInterface } from "../../../assets/interfaces";
import { DeliveryInfoData } from "../../orders/delivery-info";
import { MobileItemsTable } from "../../orders/order-items";
import { DeliveryDetails, OrderDetails } from "../../orders/orders-data";
import { AppBtn } from "../../ui/buttons";
import ContentWithCopy from "../../ui/content-with-copy";
import DataAccordion from "../../ui/data-accordion";
import Modal, { ModalBody, ModalFooter } from "../../ui/modal";

interface Props {
  show: boolean;
  toggle: (state: boolean) => void;
  order: OrderInterface;
}
export const OrderSummaryModal: React.FC<Props> = ({ show, toggle, order }) => {
  return (
    <Modal title="Order Summary" {...{ show, toggle }} size="midi">
      <ModalBody>
        <div>
          <div className="flex items-center justify-between text-sm mb-2.5">
            <span className="text-dark">Order ID:</span>
            <ContentWithCopy text={order.id}>
              <span className="text-black-secondary font-medium">{order?.id}</span>
            </ContentWithCopy>
          </div>
          <div className="w-full table mb-6.25">
            <MobileItemsTable order={order} />
          </div>
          {order?.delivery_info && (
            <DataAccordion title="Delivery Details">
              <div className="mt-3.75">
                <DeliveryInfoData deliveryInfo={order?.delivery_info} isSeller={false} />
              </div>
            </DataAccordion>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="w-full">
          <AppBtn isBlock color="primary" onClick={() => toggle(false)} size="lg">
            Go back
          </AppBtn>
        </div>
      </ModalFooter>
    </Modal>
  );
};
