import classNames from "classnames";
import React, { useRef, useState } from "react";

interface Props {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  placeholder?: string;
  className?: string;
  setOnSubmit?: boolean;
  inputClasses?: string;
}

const SearchBar: React.FC<Props> = ({
  searchQuery,
  setSearchQuery,
  placeholder = "Search items",
  className,
  setOnSubmit = false,
  inputClasses,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState<string>();
  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    setSearchQuery(inputRef.current?.value);
  };
  return (
    <form onSubmit={handleSearch} className={`flex items-center relative ${className}`}>
      <button onClick={handleSearch} className="w-5 text-dark absolute left-3.75">
        {/* prettier-ignore */}
        <svg  viewBox="0 0 20 20" fill="none" >
          <mask id="mask0_3694_21047" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="1" y="1" width="17" height="17">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.66675 1.66675H17.8974V17.8976H1.66675V1.66675Z" fill="white"/>
          </mask>
          <g mask="url(#mask0_3694_21047)">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.78234 2.91675C5.9965 2.91675 2.9165 5.99591 2.9165 9.78175C2.9165 13.5676 5.9965 16.6476 9.78234 16.6476C13.5673 16.6476 16.6473 13.5676 16.6473 9.78175C16.6473 5.99591 13.5673 2.91675 9.78234 2.91675M9.78234 17.8976C5.30734 17.8976 1.6665 14.2567 1.6665 9.78175C1.6665 5.30675 5.30734 1.66675 9.78234 1.66675C14.2573 1.66675 17.8973 5.30675 17.8973 9.78175C17.8973 14.2567 14.2573 17.8976 9.78234 17.8976" fill="currentColor"/>
          </g>
          <mask id="mask1_3694_21047" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="14" y="14" width="5" height="5">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.3667 14.7559H18.5534V18.9348H14.3667V14.7559Z" fill="white"/>
          </mask>
          <g mask="url(#mask1_3694_21047)">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.9286 18.9348C17.7694 18.9348 17.6094 18.874 17.4869 18.7523L14.5502 15.824C14.3061 15.5798 14.3052 15.184 14.5494 14.9398C14.7927 14.694 15.1886 14.6957 15.4336 14.9382L18.3702 17.8673C18.6144 18.1115 18.6152 18.5065 18.3711 18.7507C18.2494 18.874 18.0886 18.9348 17.9286 18.9348" fill="currentColor"/>
          </g>
        </svg>
      </button>

      <input
        ref={inputRef}
        className={classNames(
          "outline-none h-12.5 border bg-grey-fields-100 rounded-xl text-base placeholder-dark border-grey-fields-100 p-3.75 w-full leading-none pl-11 transition-all focus:placeholder-opacity-60 focus:bg-white focus:border-primary-500 focus:border-opacity-60 text-black focus:outline-none",
          inputClasses
        )}
        type="text"
        placeholder={placeholder}
        value={setOnSubmit ? query : searchQuery}
        onChange={(e) => (setOnSubmit ? setQuery(e.target.value) : setSearchQuery(e.target.value))}
      />
    </form>
  );
};

export default SearchBar;
