import React from "react";
import { DeliveryIcon, DiscountsIcon, OtherFeesIcon, VATIcon } from "../../assets/icons/order-fees";
import { OrderInterface, ORDER_FEES } from "../../assets/interfaces";
import { amountFormat, toCurrency } from "../../assets/js/utils/functions";
import { TableCell, TableRow } from "../ui/table";

const FeesAndTotal: React.FC<{ order: OrderInterface; ignoreWideScreens?: boolean }> = ({
  order,
  ignoreWideScreens,
}) => {
  return (
    <>
      {order.fees.map((fee, index) => (
        <TableRow>
          <TableCell className="flex items-center !pr-2 sm:!pr-5 !pl-3 sm:!pl-7.5">
            <figure
              className={`flex items-center justify-center w-7 h-7 md:w-7.5 md:h-7.5 rounded-full bg-grey-fields-100 text-black-placeholder`}
            >
              <div className="w-4.5">{feeIcons[fee.type]()}</div>
            </figure>
            <span className="ml-2.5 font-medium  whitespace-pre-wrap text-1xs sm:text-sm">{fee.label}</span>
          </TableCell>
          {!ignoreWideScreens && (
            <>
              <TableCell className="hidden md:table-cell"></TableCell>
              <TableCell className="hidden md:table-cell"></TableCell>
            </>
          )}
          <TableCell className="text-right text-black text-1xs sm:text-sm font-medium !pl-2 sm:!pl-5 !pr-3 sm:!pr-7.5">
            {amountFormat(fee.amount)}
          </TableCell>
        </TableRow>
      ))}
      <TableRow className="!border-b-0 !border-t-0">
        <TableCell className="bg-grey-fields-200 text-1xs sm:text-sm font-semibold !rounded-l-8 text-dark">
          GRAND TOTAL
        </TableCell>
        {!ignoreWideScreens && (
          <>
            <TableCell className="bg-grey-fields-200 hidden md:table-cell"></TableCell>
            <TableCell className="bg-grey-fields-200 hidden md:table-cell"></TableCell>
          </>
        )}
        <TableCell className="bg-grey-fields-200 text-1xs sm:text-sm md:text-1sm font-semibold !rounded-r-8 text-right text-black">
          {toCurrency(order.total_amount, order.currency)}
        </TableCell>
      </TableRow>
    </>
  );
};

const feeIcons = {
  [ORDER_FEES.VAT]: VATIcon,
  [ORDER_FEES.OTHER]: OtherFeesIcon,
  [ORDER_FEES.DELIVERY]: DeliveryIcon,
  [ORDER_FEES.DISCOUNT]: DiscountsIcon,
  [ORDER_FEES.COUPON]: DiscountsIcon,
};

export default FeesAndTotal;
