import React from "react";
import { AccountInformation, WhatsappCheckoutOption, OrderInterface } from "@/assets/interfaces";
import getWhatsappLink, { getOrderMessage } from "@/assets/js/utils/get-whatsapp-order-link";
import { AppBtn } from "@/components/ui/buttons";
import { ModalBody, ModalFooter } from "@/components/ui/modal";
import SuccessAnimation from "@/components/ui/success-animation";
import { toAppUrl } from "@/assets/js/utils/functions";
import useCopyClipboard from "@/components/hooks/useCopyClipboard";

interface SuccessProps {
  storeName: string;
  closeModal: () => void;
  checkoutOption: WhatsappCheckoutOption;
  order: OrderInterface;
  account: AccountInformation;
}

const OrderSuccess: React.FC<SuccessProps> = ({ storeName, closeModal, checkoutOption, account, order }) => {
  const [copied, copy] = useCopyClipboard("", {
    successDuration: 500,
    successTitle: "Copied Successfully!",
    successMessage: "Order message copied to clipboard",
  });

  const openLink = (link: string) => {
    window.open(link, "_blank").focus();
  };

  return (
    <>
      <ModalBody>
        <div className="pt-8">
          <div className="flex flex-col items-center">
            <SuccessAnimation />
            <h1 className="text-xl sm:text-2lg md:text-2xl font-medium text-center mt-5 !leading-tight">
              Thank you for <span className="font-bold">Your Order!</span>
            </h1>
            {/* <span className="text-sm sm:text-1sm text-center text-dark">Order Received</span> */}
          </div>
          <ul className="mx-auto w-80 rounded-10 bg-grey-fields-100 mt-4.5 divide-y divide-grey-border divide-opacity-40">
            <li className="">
              <button
                className="w-full flex items-center justify-between py-3 px-4 no-outline text-primary-500"
                onClick={() => openLink(toAppUrl(`/orders/${order?.id}`))}
              >
                <span className="text-sm font-medium">View or Edit Order</span>
                {/* prettier-ignore */}
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" >
                  <path d="M3.53589 11.0359L10.607 3.96482" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.6062 11.0359L10.6062 3.96482L3.53513 3.96482" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </li>
            <li className="">
              <button
                className="w-full flex items-center justify-between py-3 px-4 no-outline text-primary-500"
                onClick={() => copy(getOrderMessage(order, "Hi, i'll like to buy these items:", account, null, true))}
              >
                <span className="text-sm font-medium">Copy Order Message</span>
                {/* prettier-ignore */}
                <svg width="15" viewBox="0 0 24 24" fill="none">
                  <path d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </li>
            <li className="">
              <button
                className="w-full flex items-center justify-between py-3 px-4 no-outline text-primary-500"
                onClick={() =>
                  openLink(
                    getWhatsappLink(order, "Hi, i'll like to buy these items:", checkoutOption.phone, account, null)
                  )
                }
              >
                <span className="text-sm font-medium">Resend To Whatsapp</span>
                {/* prettier-ignore */}
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" >
                  <path d="M3.53589 11.0359L10.607 3.96482" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.6062 11.0359L10.6062 3.96482L3.53513 3.96482" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </li>
            {order?.invoice && (
              <li>
                <button
                  className="w-full flex items-center justify-between py-3 px-4 no-outline text-primary-500"
                  onClick={() => openLink(toAppUrl(`/invoices/${order?.invoice}`))}
                >
                  <span className="text-sm font-medium">Make Payment</span>
                  {/* prettier-ignore */}
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" >
                    <path d="M3.53589 11.0359L10.607 3.96482" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.6062 11.0359L10.6062 3.96482L3.53513 3.96482" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
              </li>
            )}
          </ul>
        </div>
      </ModalBody>
      <ModalFooter>
        {/* <AppBtn color="neutral" isBlock className="flex-1 mr-2.5"  size="lg">
          Resend to whatsapp
        </AppBtn> */}
        <AppBtn isBlock size="lg" onClick={() => closeModal()} className="flex-1">
          Close
        </AppBtn>
      </ModalFooter>
    </>
  );
};

export default OrderSuccess;
