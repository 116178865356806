import React, { ReactElement } from "react";
import { CartItem, OrderInterface, ORDER_FEES } from "../../assets/interfaces";
import { amountFormat, genarateStringFromVariantValues, toCurrency } from "../../assets/js/utils/functions";
import RoundActionButton from "../ui/buttons/round-action-button";
import Table, { TableBody, TableCell, TableHead, TableHeadItem, TableRow } from "../ui/table";
import { DeliveryIcon, DiscountsIcon, OtherFeesIcon, VATIcon } from "../../assets/icons/order-fees";
import FeesAndTotal from "./order-fees";
import Badge from "../ui/badge";
import { getActualPrice } from "../../assets/js/utils/utils";

interface IProps {
  editItems: VoidFunction;
  order: OrderInterface;
  isSeller?: boolean;
  editFees: VoidFunction;
}

const OrderItems: React.FC<IProps> = ({ editItems, order, editFees, isSeller = false }) => {
  const items = order.items;

  return (
    <section className="w-full max-w-full">
      <div className="flex items-center justify-between py-1.25 md:py-2 mb-2.5">
        <h2 className="text-black text-1sm sm:text-lg font-bold">Order Summary</h2>
        <RoundActionButton onClick={editItems} icon="edit" />
      </div>
      <Table className="hidden md:table">
        <TableHead>
          <TableHeadItem>Product</TableHeadItem>
          <TableHeadItem>Price</TableHeadItem>
          <TableHeadItem>QTY</TableHeadItem>
          <TableHeadItem className="text-right">Amount</TableHeadItem>
        </TableHead>
        <TableBody>
          {items.map((orderItem, index) => (
            <ItemRow orderItem={orderItem} key={index} />
          ))}
          <FeesAndTotal order={order} />
        </TableBody>
      </Table>

      <div className="table md:hidden w-full">
        <MobileItemsTable order={order} />
      </div>

      {isSeller && (
        <div className="flex justify-end mt-3.75">
          <button className="flex items-center text-primary-500 text-sm" onClick={editFees}>
            <span className="mr-2 font-semibold">Edit Fees</span>
            {/* prettier-ignore */}
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
              <path d="M8.83958 2.9L3.36624 8.69334C3.15958 8.91334 2.95958 9.34667 2.91958 9.64667L2.67291 11.8067C2.58624 12.5867 3.14624 13.12 3.91958 12.9867L6.06624 12.62C6.36624 12.5667 6.78624 12.3467 6.99291 12.12L12.4662 6.32667C13.4129 5.32667 13.8396 4.18667 12.3662 2.79334C10.8996 1.41334 9.78624 1.9 8.83958 2.9Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.92578 3.86667C8.21245 5.70667 9.70578 7.11334 11.5591 7.3" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2 15.1667H14" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      )}
    </section>
  );
};

const ItemRow: React.FC<{ orderItem: CartItem }> = ({ orderItem }) => {
  const isImageVariant = orderItem.item?.variants?.type === "images";
  const isCustomVariant = orderItem.item?.variants?.type === "custom";
  const variantHasExtraOption = isImageVariant && orderItem.item?.variants?.options[0]?.values !== undefined;

  return (
    <TableRow>
      <TableCell className="flex items-center">
        <figure className="h-9 w-9 lg:w-10 lg:h-10 rounded-md overflow-hidden flex-shrink-0">
          <img
            src={isImageVariant ? orderItem.variant.image : orderItem.item?.images[orderItem.item?.thumbnail]}
            alt=""
            className="h-full w-full object-cover"
          />
        </figure>
        <span className="font-medium ml-2.5 text-dark">{orderItem.item.name}</span>
        {isCustomVariant && (
          <span className="uppercase text-accent-orange-500 font-medium text-xxs inline-block ml-1.5">
            - {genarateStringFromVariantValues(orderItem?.variant?.values)}
          </span>
        )}
        {variantHasExtraOption && (
          <span className="uppercase text-accent-orange-500 font-medium text-xxs inline-block ml-1.5">{`- ${
            Object.keys(orderItem?.variant?.values)[0]
          } ${Object.values(orderItem?.variant?.values)[0]}`}</span>
        )}
      </TableCell>
      <TableCell className="text-black-secondary">
        {amountFormat(getActualPrice(orderItem.variant ? orderItem.variant : orderItem.item))}
      </TableCell>
      <TableCell className="">{orderItem.quantity}</TableCell>
      <TableCell className="font-medium text-black-secondary text-right">
        {amountFormat(orderItem.quantity * getActualPrice(orderItem.variant ? orderItem.variant : orderItem.item))}
      </TableCell>
    </TableRow>
  );
};

interface MobileTableProps {
  order: OrderInterface;
}

export const MobileItemsTable: React.FC<MobileTableProps> = ({ order }) => {
  const items = order.items;

  return (
    <Table>
      <TableHead className="">
        <TableHeadItem className="!pl-3 sm:!pl-7.5">PRODUCT/QTY</TableHeadItem>
        <TableHeadItem className="text-right !pr-3 sm:!pr-7.5">Price</TableHeadItem>
      </TableHead>
      <TableBody className="!border-0">
        {items.map((orderItem, index) => (
          <MobileItemRow orderItem={orderItem} key={index} />
        ))}
        <FeesAndTotal order={order} ignoreWideScreens />
      </TableBody>
    </Table>
  );
};

const MobileItemRow: React.FC<{ orderItem: CartItem }> = ({ orderItem }) => {
  const isImageVariant = orderItem.item?.variants?.type === "images";
  const isCustomVariant = orderItem.item?.variants?.type === "custom";
  const variantHasExtraOption = isImageVariant && orderItem.item?.variants?.options[0]?.values !== undefined;

  return (
    <TableRow>
      <TableCell className="flex items-center !pr-2 sm:!pr-5 !pl-3 sm:!pl-7.5">
        <figure className="h-9 w-9 sm:h-10 sm:w-10 rounded-md overflow-hidden flex-shrink-0">
          <img
            src={isImageVariant ? orderItem.variant.image : orderItem.item?.images[orderItem.item?.thumbnail]}
            alt={orderItem?.item?.name}
            className="h-full w-full object-cover"
          />
        </figure>
        <div className="flex flex-col ml-2.5 max-w-[130px] overflow-hidden">
          <div className="flex items-center w-full overflow-hidden">
            <span className="text-black text-sm font-medium inline-block overflow-hidden overflow-ellipsis max-w-full whitespace-nowrap">
              {orderItem.item.name}
            </span>

            {isCustomVariant && (
              <span className="uppercase text-accent-orange-500 font-medium text-xxs inline-block ml-1.5">
                - {genarateStringFromVariantValues(orderItem?.variant?.values)}
              </span>
            )}
            {variantHasExtraOption && (
              <span className="uppercase text-accent-orange-500 font-medium text-xxs inline-block ml-1.5">{`- ${
                Object.keys(orderItem?.variant?.values)[0]
              } ${Object.values(orderItem?.variant?.values)[0]}`}</span>
            )}
          </div>
          <span className="text-black-placeholder text-xs sm:text-1xs font-medium">Quantity: {orderItem.quantity}</span>
        </div>
      </TableCell>
      <TableCell className="text-right !pl-2 sm:!pl-5 !pr-3 sm:!pr-7.5">
        <div className="flex flex-col">
          <span className="text-black text-1xs sm:text-sm font-medium">
            {amountFormat(orderItem.quantity * getActualPrice(orderItem.variant ? orderItem.variant : orderItem.item))}
          </span>
          <span className="text-black-placeholder text-xs sm:text-1xs font-medium">
            {getActualPrice(orderItem.variant ? orderItem.variant : orderItem.item)}
          </span>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default OrderItems;
