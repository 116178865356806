import classNames from "classnames";
import { useState } from "react";

const DataAccordion: React.FC<{ title: string; deactivated?: boolean; hideButton?: boolean }> = ({
    title,
    deactivated = false,
    children,
  }) => {
    const [showContent, setShowContent] = useState(true);
    const transition = "transition-all ease-out duration-300";
  
    const accordionClasses = classNames(transition, {
      "max-h-0 overflow-hidden": !showContent,
      "max-h-[1000px]": showContent,
    });
  
    return (
      <div className="pb-5 rounded-15 relative">
        <div
          className={`flex items-center justify-between ${!deactivated && "cursor-pointer"}`}
          onClick={() => !deactivated && setShowContent(!showContent)}
        >
          <h5 className="font-semibold font-display text-black-secondary">{title}</h5>
          {/* prettier-ignore */}
          {!deactivated && (
            <button
              className={`transition-all duration-100 bg-grey-fields-200 rounded-full p-1.5 ease-out transform ${
                showContent ? "" : "rotate-180"
              }`}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M17 14L12 9L7 14" stroke="#656565" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          )}
        </div>
        <div className={accordionClasses}>{children}</div>
      </div>
    );
  };
 export default DataAccordion 