import classNames from "classnames";

interface RadioProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string;
  name: string;
  checked: boolean;
  small?: boolean;
  onChange?: (value: any) => void;
  // children: React.ReactElement;
}

const RoundCheckbox: React.FC<RadioProps> = (props) => {
  const { className, name, value, checked, onChange, small = false, disabled, children } = props;

  return (
    <div
      className={classNames(
        `inline-flex`,
        {
          "h-4.5 w-4.5": small,
          "h-6 w-6": !small,
        },
        className
      )}
      onClick={(e) => {
        e.stopPropagation();

        if (disabled) {
          return;
        }

        if (onChange) {
          onChange(value);
        }
      }}
    >
      <input type="checkbox" {...props} className="hidden" />
      <label htmlFor={name} className="inline-flex items-center text-sm cursor-pointer w-full h-full relative">
        <div
          className={classNames(
            "h-full w-full rounded-full transition-all ease-out duration-200 absolute top-0 left-0 transform border-grey-border",
            {
              "border-2": small,
              "border-[3px]": !small,
              "scale-1 opacity-1": !checked,
              "opacity-0 scale-0 pointer-events-none": checked,
              "border-opacity-70 cursor-not-allowed": disabled,
            }
          )}
        ></div>
        <div
          className={classNames(
            "h-full w-full transition-all ease-out duration-200 absolute top-0 left-0 transform text-success bg-white rounded-full",
            {
              "scale-1 opacity-1": checked,
              "opacity-0 scale-0 pointer-events-none": !checked,
            }
          )}
        >
          <svg className="h-full w-full" width="100%" viewBox="0 0 20 20" fill="none">
            <path
              d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM14.78 7.7L9.11 13.37C8.97 13.51 8.78 13.59 8.58 13.59C8.38 13.59 8.19 13.51 8.05 13.37L5.22 10.54C4.93 10.25 4.93 9.77 5.22 9.48C5.51 9.19 5.99 9.19 6.28 9.48L8.58 11.78L13.72 6.64C14.01 6.35 14.49 6.35 14.78 6.64C15.07 6.93 15.07 7.4 14.78 7.7Z"
              fill="currentColor"
            />
          </svg>
        </div>
        {children}
      </label>
    </div>
  );
};

export default RoundCheckbox;
