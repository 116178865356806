import classNames from "classnames";

interface RadioProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string;
  name: string;
  value: any;
  chosen: any;
  small?: boolean;
  onChange: (value: any) => void;
  innerChild?: React.ReactElement;
}

const Radio: React.FC<RadioProps> = (props) => {
  const { className, name, value, chosen, onChange, innerChild, small } = props;

  const isChecked = value === chosen;

  return (
    <div className={`inline-flex ${className || ""}`}>
      <input type="radio" {...props} className="hidden" />
      <label
        htmlFor={name}
        className="inline-flex items-center text-sm cursor-pointer"
        onClick={(e) => {
          if (props.disabled) return;
          e.stopPropagation();
          onChange(value);
        }}
      >
        <div
          className={classNames("relative", {
            "h-5 w-5": !small,
            "h-4 w-4": small,
          })}
        >
          <div
            className={classNames(
              "h-full w-full rounded-full !border-grey-border transition-all ease-out duration-200 absolute top-0 left-0 transform border-2",
              {
                "scale-1 opacity-1": !isChecked,
                "opacity-0 scale-0 pointer-events-none": isChecked,
              }
            )}
          ></div>
          {/* prettier-ignore */}
          <svg 
            width="100%" 
            viewBox="0 0 18 18" 
            fill="none" 
            className={classNames(
              "h-full w-full transition-all ease-out duration-200 absolute top-0 left-0 transform text-success",
              {
                "scale-1 opacity-1": isChecked,
                "opacity-0 scale-0 pointer-events-none": !isChecked,
              }
            )}>
            <rect width="18" height="18" rx="9" fill="currentColor"/>
            <path d="M7.72136 12.375C7.54127 12.375 7.37019 12.2997 7.24412 12.1678L4.69585 9.50266C4.43472 9.22955 4.43472 8.77751 4.69585 8.5044C4.95698 8.2313 5.38919 8.2313 5.65033 8.5044L7.72136 10.6704L12.3497 5.82983C12.6108 5.55672 13.043 5.55672 13.3042 5.82983C13.5653 6.10294 13.5653 6.55498 13.3042 6.82809L8.1986 12.1678C8.07254 12.2997 7.90145 12.375 7.72136 12.375Z" fill="white"/>
          </svg>
        </div>
        {innerChild}
      </label>
    </div>
  );
};

export default Radio;
