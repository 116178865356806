import React, { useEffect, useMemo, useState } from "react";
import Modal, { ModalBody, ModalFooter } from "../../ui/modal";
import { AppBtn } from "../../ui/buttons";
import { genarateStringFromVariantValues, toCurrency } from "../../../assets/js/utils/functions";
import SelectVariantExtra from "../../products/variants/select-variant-extra";
import { getActualPrice, checkItemAvailability } from "../../../assets/js/utils/utils";
import { VariantItem, ProductItemInterface, CartItem } from "../../../assets/interfaces";
import RoundCheckbox from "../../ui/form-elements/round-checkbox";
import Badge from "../../ui/badge";
import { FormatCurrencyFun } from "../../../contexts/cart-context";

interface Props {
  show: boolean;
  toggle: (status: boolean) => void;
  product: ProductItemInterface;
  cart: CartItem[];
  toggleCartItem: (item: ProductItemInterface, variants?: string[]) => void;
  formatAsCurrency: FormatCurrencyFun;
}

const SelectVariantModal: React.FC<Props> = ({ show, toggle, product, cart, toggleCartItem, formatAsCurrency }) => {
  const selectedItemVariantsInCart = useMemo(
    () => cart.filter((c) => c.item_id === product?.id && !(c.is_deleted || c.is_unavailable)).map((c) => c.variant_id),
    [cart, product]
  );
  const [selected, setSelected] = useState([]);
  const variantHasExtraOption =
    product?.variants.type === "images" && product?.variants?.options[0]?.values !== undefined;

  const extraOption = variantHasExtraOption ? Object.keys(product?.variants?.options[0]?.values)[0] : "";
  const [step, setStep] = useState<"start" | "extra">("start");
  const [selectedImages, setSelectedImages] = useState<string[]>([]);

  useEffect(() => {
    setSelected(selectedItemVariantsInCart);

    if (variantHasExtraOption) {
      setSelectedImages(
        selectedItemVariantsInCart.map((s) => product?.variants?.options?.find((o) => o.id === s)?.image)
      );
    }
  }, [selectedItemVariantsInCart]);

  useEffect(() => {
    setStep("start");
  }, [product]);

  const variantIsSelected = (variant: string) => {
    if (variantHasExtraOption) {
      return selectedImages.includes(variant);
    }

    return selected.includes(variant);
  };

  const toggleVariant = (variant: string) => {
    const dataToCheck = variantHasExtraOption ? selectedImages : selected;
    const setMethod = variantHasExtraOption ? setSelectedImages : setSelected;
    const variantIndex = dataToCheck.indexOf(variant);

    if (variantIndex > -1) {
      const selectedCopy = [...dataToCheck];
      selectedCopy.splice(variantIndex, 1);

      setMethod(selectedCopy);
    } else {
      setMethod([...dataToCheck, variant]);
    }
  };

  const updateCart = () => {
    if (variantHasExtraOption && step === "start") {
      if (selectedImages.length === 0) {
        toggleCartItem(product, selectedItemVariantsInCart);
        toggle(false);
      } else {
        setStep("extra");
      }
    } else {
      const variantsToRemove = selectedItemVariantsInCart.filter((v) => selected.indexOf(v) === -1);
      const newVariantsToAdd = selected.filter((s) => selectedItemVariantsInCart.indexOf(s) === -1);
      const variantsToToggle = [...variantsToRemove, ...newVariantsToAdd];

      toggleCartItem(product, variantsToToggle);
      setStep("start");
      toggle(false);
    }
  };

  const getImageVariants = (): VariantItem[] => {
    if (variantHasExtraOption) {
      // const optionValues = Array.from(new Set(product?.variants.options.map((v) => Object.values(v.values)[0])));
      const optionImages: string[] = Array.from(new Set(product?.variants.options.map((o) => o.image)));

      const optionsAvailablity = product?.variants.options.map((o) => ({
        image: o.image,
        available: checkItemAvailability(product, o, true),
      }));

      return optionImages.map((o) => {
        return {
          id: o,
          image: o,
          price: 0,
          is_available: product?.variants.options.some((v) => v.image === o && v.is_available),
          available_to_purchase: optionsAvailablity.some((v) => v.image === o && v.available),
        };
      });
    }

    return product?.variants.options.map((o) => ({
      ...o,
      available_to_purchase: checkItemAvailability(product, o, true),
    }));
  };

  const getCustomVariants = (): VariantItem[] => {
    return product?.variants.options.map((o) => ({
      ...o,
      available_to_purchase: checkItemAvailability(product, o, true),
    }));
  };

  return (
    <Modal {...{ show, toggle }} title="Select Options">
      <ModalBody>
        <div>
          <div className="w-full bg-grey-fields-100 rounded-5 flex items-center text-dark text-1xs py-2.5 px-4 justify-between">
            <span>Select some variants</span> <b className="font-semibold">{selected.length} selected</b>
          </div>

          {product?.variants.type === "images" && step === "start" && (
            <div className="grid items-start w-full grid-cols-[repeat(auto-fit,120px)] sm:grid-cols-[repeat(auto-fit,150px)] gap-2.5 mt-5">
              {getImageVariants().map((option, index) => (
                <figure
                  className="relative w-full h-[120px] sm:h-[150px] rounded-10 overflow-hidden cursor-pointer"
                  key={index}
                  onClick={(e) => {
                    option.available_to_purchase && toggleVariant(option.id);
                  }}
                >
                  <img src={option.image} alt="" className="w-full object-cover h-full" />

                  <div className="absolute inset-0 bg-black bg-opacity-20 p-2.5">
                    {option.available_to_purchase && (
                      <RoundCheckbox
                        name={`variant-option`}
                        id={option.id}
                        checked={variantIsSelected(option.id)}
                        small
                        disabled={!option.available_to_purchase}
                        onChange={() => (option.available_to_purchase ? toggleVariant(option.id) : null)}
                      />
                    )}

                    {!option.available_to_purchase && (
                      <span className="bg-white px-2 absolute text-accent-red-500 py-1 text-xxxs right-2.5 bottom-2.5 inline-flex font-semibold uppercase rounded-30">
                        Unavailable
                      </span>
                      // <Badge text="Unavailable" color="red" className="absolute bottom-2.5 right-2.5" size="sm" />
                    )}

                    {option.available_to_purchase && option.price > 0 && (
                      <span className="absolute bottom-2.5 right-2.5 bg-black bg-opacity-30 text-white font-semibold px-1.5 pb-0.5 pt-1 rounded-5 text-xxs ">
                        {formatAsCurrency(getActualPrice(option))}
                      </span>
                    )}
                  </div>
                </figure>
              ))}
            </div>
          )}

          {product?.variants.type === "images" && step === "extra" && (
            <SelectVariantExtra
              {...{
                selectedImages,
                options: product?.variants.options,
                extraOption,
                selected,
                setSelected,
                formatAsCurrency,
                product,
              }}
            />
          )}

          {product?.variants.type === "custom" && (
            <div className="flex flex-col items-start mt-3">
              {getCustomVariants().map((option, index) => (
                <button
                  className={`inline-flex bg-grey-light px-2 py-1 rounded-5 items-center my-1.5`}
                  onClick={(e) => {
                    option.available_to_purchase && toggleVariant(option.id);
                  }}
                  key={index}
                >
                  <RoundCheckbox
                    name={`variant-option`}
                    id={option.id}
                    checked={variantIsSelected(option.id)}
                    small
                    onChange={() => toggleVariant(option.id)}
                    disabled={!option.available_to_purchase}
                  ></RoundCheckbox>
                  <span
                    className={`text-1xs inline-block ml-1.5 ${
                      !option.available_to_purchase ? "text-black-placeholder" : "text-black-secondary font-medium"
                    }`}
                  >
                    {genarateStringFromVariantValues(option.values)} - {formatAsCurrency(getActualPrice(option))}
                  </span>

                  {!option.available_to_purchase && (
                    <span className="text-accent-red-500 font-semibold text-xxxs inline-block ml-1.5 uppercase">
                      Unavilable
                    </span>
                  )}
                </button>
              ))}
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="flex items-center space-x-2.5 w-full">
          {variantHasExtraOption && step === "extra" && (
            <div className="flex-1 w-full">
              <AppBtn color="neutral" isBlock onClick={() => setStep("start")} size="lg">
                Back
              </AppBtn>
            </div>
          )}
          <div className="flex-1 w-full">
            <AppBtn isBlock onClick={() => updateCart()} size="lg">
              {variantHasExtraOption && step === "start" ? "Next" : "Update Cart"}
            </AppBtn>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default SelectVariantModal;
