import classNames from "classnames";
import { Fragment } from "react";
import useCopyClipboard from "../hooks/useCopyClipboard";

const ContentWithCopy: React.FC<{
  text: string;
  small?: boolean;
  className?: string;
}> = ({ text, className, children, small }) => {
  const [copied, copy] = useCopyClipboard(text, { successDuration: 1000 });

  return (
    <div className={classNames("flex items-center cursor-pointer", className)} onClick={() => copy(text)}>
      <div className=" overflow-hidden flex items-center">{children}</div>
      <button
        type="button"
        className="h-5 w-5 rounded-5 bg-grey-fields-100 flex items-center justify-center ml-1.25 flex-shrink-0 text-primary-500"
      >
        {/* prettier-ignore */}
        <svg className={classNames("w-3.5 h-3.5",{small: "w-2 h-2"})} viewBox="0 0 14 15" fill="none">
            <path d="M6.47508 13.6556H4.02508C1.74425 13.6556 0.729248 12.6406 0.729248 10.3598V7.90977C0.729248 5.62894 1.74425 4.61394 4.02508 4.61394H6.47508C8.75591 4.61394 9.77091 5.62894 9.77091 7.90977V10.3598C9.77091 12.6406 8.75591 13.6556 6.47508 13.6556ZM4.02508 5.48894C2.21675 5.48894 1.60425 6.10144 1.60425 7.90977V10.3598C1.60425 12.1681 2.21675 12.7806 4.02508 12.7806H6.47508C8.28341 12.7806 8.89591 12.1681 8.89591 10.3598V7.90977C8.89591 6.10144 8.28341 5.48894 6.47508 5.48894H4.02508Z" fill="currentColor" />
            <path d="M9.97508 10.1556H9.33341C9.09425 10.1556 8.89591 9.95727 8.89591 9.7181V7.90977C8.89591 6.10144 8.28341 5.48894 6.47508 5.48894H4.66675C4.42758 5.48894 4.22925 5.2906 4.22925 5.05144V4.40977C4.22925 2.12894 5.24425 1.11394 7.52508 1.11394H9.97508C12.2559 1.11394 13.2709 2.12894 13.2709 4.40977V6.85977C13.2709 9.1406 12.2559 10.1556 9.97508 10.1556ZM9.77091 9.2806H9.97508C11.7834 9.2806 12.3959 8.6681 12.3959 6.85977V4.40977C12.3959 2.60144 11.7834 1.98894 9.97508 1.98894H7.52508C5.71675 1.98894 5.10425 2.60144 5.10425 4.40977V4.61394H6.47508C8.75591 4.61394 9.77091 5.62894 9.77091 7.90977V9.2806Z" fill="currentColor" />
          </svg>
      </button>
    </div>
  );
};

export default ContentWithCopy;
