import React from "react";
import { UpdateOrderFromCustomerParams } from "../../api/interfaces/orders-customers.interface";
import { UpdateOrderFromCustomer } from "../../api/orders-customers";
import { useRequest } from "../../api/utils";
import { DeliveryArea, DELIVERY_METHODS, OrderInterface } from "../../assets/interfaces";
import { getWhatsappLink, resolvePhone } from "../../assets/js/utils/utils";
import { useModals } from "../hooks/useModals";
import Portal from "../portal";
import RoundActionButton from "../ui/buttons/round-action-button";
// import { resolvePhone } from "../store-front/modals/cart";
import { EditDeliveryDetails } from "./modals/edit-delivery-details";
interface Props {
  order: OrderInterface;
  isSeller?: boolean;
  editDeliveryInfo: VoidFunction;
}
const OrderDeliveryInfo: React.FC<Props> = ({ order, isSeller, editDeliveryInfo }) => {
  const deliveryInfo = order.delivery_info;

  return (
    <section className="mt-10">
      <div className="flex items-center justify-between py-1.25 md:py-2 mb-2.5">
        <h2 className="text-black text-1sm sm:text-lg font-bold">Delivery details</h2>
        {!isSeller && <RoundActionButton onClick={editDeliveryInfo} icon="edit" />}
      </div>
      {order.delivery_method === DELIVERY_METHODS.PICKUP && (
        <dl className="bg-grey-fields-200 rounded-15 p-2.5 md:py-2.5 md:px-5">
          <div className="flex items-center justify-between py-2 md:py-2.5 w-full">
            <div className="flex items-start w-full">
              <figure className="h-7.5 w-7.5 md:h-8 md:w-8 flex items-center justify-center text-black-placeholder rounded-full bg-white flex-shrink-0">
                {icons.address}
              </figure>{" "}
              <div className="ml-2 md:ml-3.75">
                <dt className="text-black-secondary text-sm sm:text-1sm font-medium mb-1">Delivery Method</dt>
                <dd className="text-dark text-1xs sm:text-sm">Pickup</dd>
              </div>
            </div>
          </div>
        </dl>
      )}
      {order?.delivery_method !== DELIVERY_METHODS.PICKUP && order?.delivery_info && (
        <DeliveryInfoData deliveryInfo={deliveryInfo} isSeller={isSeller} />
      )}
    </section>
  );
};

export const DeliveryInfoData: React.FC<{ deliveryInfo: OrderInterface["delivery_info"]; isSeller: boolean }> = ({
  deliveryInfo,
  isSeller,
}) => {
  return (
    <dl className="bg-grey-fields-200 rounded-15 p-2.5 md:py-2.5 md:px-5">
      <div className="flex items-center justify-between py-2 md:py-2.5 w-full">
        <div className="flex items-start w-full">
          <figure className="h-7.5 w-7.5 md:h-8 md:w-8 flex items-center justify-center text-black-placeholder rounded-full bg-white flex-shrink-0">
            {icons.name}
          </figure>
          <div className="ml-2 md:ml-3.75 flex-1">
            <dt className="text-black-secondary text-sm sm:text-1sm font-medium mb-1">{deliveryInfo.name}</dt>
            <dd className="text-dark text-1xs sm:text-sm flex items-center w-full justify-between">
              <span>{resolvePhone(deliveryInfo.phone)}</span>
              {isSeller && (
                <div className="flex items-center border-grey-border space-x-1.5">
                  <a
                    className="h-4 w-4 text-placeholder"
                    href={getWhatsappLink(deliveryInfo.phone)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {/* prettier-ignore */}
                    <svg width="100%" viewBox="0 0 24 24" fill="none">
                      <path d="M6.9 20.6C8.4 21.5 10.2 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 13.8 2.5 15.5 3.3 17L2.44044 20.306C2.24572 21.0549 2.93892 21.7317 3.68299 21.5191L6.9 20.6Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16.5 14.8485C16.5 15.0105 16.4639 15.177 16.3873 15.339C16.3107 15.501 16.2116 15.654 16.0809 15.798C15.86 16.041 15.6167 16.2165 15.3418 16.329C15.0714 16.4415 14.7784 16.5 14.4629 16.5C14.0033 16.5 13.512 16.392 12.9937 16.1715C12.4755 15.951 11.9572 15.654 11.4434 15.2805C10.9251 14.9025 10.4339 14.484 9.9652 14.0205C9.501 13.5525 9.08187 13.062 8.70781 12.549C8.33826 12.036 8.04081 11.523 7.82449 11.0145C7.60816 10.5015 7.5 10.011 7.5 9.543C7.5 9.237 7.55408 8.9445 7.66224 8.6745C7.77041 8.4 7.94166 8.148 8.18052 7.923C8.46895 7.6395 8.78443 7.5 9.11793 7.5C9.24412 7.5 9.37031 7.527 9.48297 7.581C9.60015 7.635 9.70381 7.716 9.78493 7.833L10.8305 9.3045C10.9116 9.417 10.9702 9.5205 11.0108 9.6195C11.0513 9.714 11.0739 9.8085 11.0739 9.894C11.0739 10.002 11.0423 10.11 10.9792 10.2135C10.9206 10.317 10.835 10.425 10.7268 10.533L10.3843 10.8885C10.3348 10.938 10.3122 10.9965 10.3122 11.0685C10.3122 11.1045 10.3167 11.136 10.3257 11.172C10.3393 11.208 10.3528 11.235 10.3618 11.262C10.4429 11.4105 10.5826 11.604 10.7809 11.838C10.9837 12.072 11.2 12.3105 11.4344 12.549C11.6778 12.7875 11.9121 13.008 12.151 13.2105C12.3853 13.4085 12.5791 13.5435 12.7323 13.6245C12.7549 13.6335 12.7819 13.647 12.8135 13.6605C12.8495 13.674 12.8856 13.6785 12.9261 13.6785C13.0028 13.6785 13.0613 13.6515 13.1109 13.602L13.4534 13.2645C13.5661 13.152 13.6743 13.0665 13.7779 13.0125C13.8816 12.9495 13.9852 12.918 14.0979 12.918C14.1835 12.918 14.2737 12.936 14.3728 12.9765C14.472 13.017 14.5756 13.0755 14.6883 13.152L16.18 14.2095C16.2972 14.2905 16.3783 14.385 16.4279 14.4975C16.473 14.61 16.5 14.7225 16.5 14.8485Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10"/>
                    </svg>
                  </a>
                  <a className="h-4 w-4 text-placeholder ml-1.25" href={`tel:${resolvePhone(deliveryInfo.phone)}`}>
                    {/* prettier-ignore */}
                    <svg width="100%" viewBox="0 0 24 24" fill="none">
                      <path d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10"/>
                    </svg>
                  </a>
                </div>
              )}
            </dd>
          </div>
        </div>
      </div>
      {deliveryInfo.delivery_area?.name && (
        <div className="flex items-center justify-between py-2 md:py-2.5">
          <div className="flex items-start">
            <figure className="h-7.5 w-7.5 md:h-8 md:w-8 flex items-center justify-center text-black-placeholder rounded-full bg-white flex-shrink-0">
              {icons.area}
            </figure>
            <div className="ml-2 md:ml-3.75">
              <dt className="text-black-secondary text-sm sm:text-1sm font-medium mb-1">Delivery Area</dt>
              <dd className="text-dark text-1xs sm:text-sm">{deliveryInfo.delivery_area?.name}</dd>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col justify-between py-2 md:py-2.5">
        <div className="flex items-start">
          <figure className="h-7.5 w-7.5 md:h-8 md:w-8 flex items-center justify-center text-black-placeholder rounded-full bg-white flex-shrink-0">
            {icons.address}
          </figure>
          <div className="ml-2 md:ml-3.75">
            <dt className="text-black-secondary text-sm sm:text-1sm font-medium">Delivery Address</dt>
            <dd className="text-dark text-1xs sm:text-sm">{deliveryInfo.delivery_address}</dd>
          </div>
        </div>
      </div>
    </dl>
  );
};

const icons = {
  address:
    // prettier-ignore
    <svg className="w-4 md:w-4.5" viewBox="0 0 18 18" fill="none">
      <path d="M9.00016 10.0725C10.2925 10.0725 11.3402 9.02485 11.3402 7.7325C11.3402 6.44016 10.2925 5.3925 9.00016 5.3925C7.70781 5.3925 6.66016 6.44016 6.66016 7.7325C6.66016 9.02485 7.70781 10.0725 9.00016 10.0725Z" stroke="currentColor" />
      <path d="M2.71478 6.3675C4.19228 -0.127498 13.8148 -0.119998 15.2848 6.375C16.1473 10.185 13.7773 13.41 11.6998 15.405C10.1923 16.86 7.80728 16.86 6.29228 15.405C4.22228 13.41 1.85228 10.1775 2.71478 6.3675Z" stroke="currentColor" />
    </svg>,
  phone:
    // prettier-ignore
    <svg className="w-4 md:w-4.5" viewBox="0 0 18 18" fill="none">
      <path d="M16.4775 13.7475C16.4775 14.0175 16.4175 14.295 16.29 14.565C16.1625 14.835 15.9975 15.09 15.78 15.33C15.4125 15.735 15.0075 16.0275 14.55 16.215C14.1 16.4025 13.6125 16.5 13.0875 16.5C12.3225 16.5 11.505 16.32 10.6425 15.9525C9.78 15.585 8.9175 15.09 8.0625 14.4675C7.2 13.8375 6.3825 13.14 5.6025 12.3675C4.83 11.5875 4.1325 10.77 3.51 9.915C2.895 9.06 2.4 8.205 2.04 7.3575C1.68 6.5025 1.5 5.685 1.5 4.905C1.5 4.395 1.59 3.9075 1.77 3.4575C1.95 3 2.235 2.58 2.6325 2.205C3.1125 1.7325 3.6375 1.5 4.1925 1.5C4.4025 1.5 4.6125 1.545 4.8 1.635C4.995 1.725 5.1675 1.86 5.3025 2.055L7.0425 4.5075C7.1775 4.695 7.275 4.8675 7.3425 5.0325C7.41 5.19 7.4475 5.3475 7.4475 5.49C7.4475 5.67 7.395 5.85 7.29 6.0225C7.1925 6.195 7.05 6.375 6.87 6.555L6.3 7.1475C6.2175 7.23 6.18 7.3275 6.18 7.4475C6.18 7.5075 6.1875 7.56 6.2025 7.62C6.225 7.68 6.2475 7.725 6.2625 7.77C6.3975 8.0175 6.63 8.34 6.96 8.73C7.2975 9.12 7.6575 9.5175 8.0475 9.915C8.4525 10.3125 8.8425 10.68 9.24 11.0175C9.63 11.3475 9.9525 11.5725 10.2075 11.7075C10.245 11.7225 10.29 11.745 10.3425 11.7675C10.4025 11.79 10.4625 11.7975 10.53 11.7975C10.6575 11.7975 10.755 11.7525 10.8375 11.67L11.4075 11.1075C11.595 10.92 11.775 10.7775 11.9475 10.6875C12.12 10.5825 12.2925 10.53 12.48 10.53C12.6225 10.53 12.7725 10.56 12.9375 10.6275C13.1025 10.695 13.275 10.7925 13.4625 10.92L15.945 12.6825C16.14 12.8175 16.275 12.975 16.3575 13.1625C16.4325 13.35 16.4775 13.5375 16.4775 13.7475Z" stroke="currentColor" strokeMiterlimit="10" />
    </svg>,
  email:
    // prettier-ignore
    <svg className="w-4 md:w-4.5" viewBox="0 0 18 18" fill="none">
      <path d="M12.75 15.375H5.25C3 15.375 1.5 14.25 1.5 11.625V6.375C1.5 3.75 3 2.625 5.25 2.625H12.75C15 2.625 16.5 3.75 16.5 6.375V11.625C16.5 14.25 15 15.375 12.75 15.375Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.75 6.75L10.4025 8.625C9.63 9.24 8.3625 9.24 7.59 8.625L5.25 6.75" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>,
  name:
    // prettier-ignore
    <svg className="w-4 md:w-4.5" viewBox="0 0 18 18" fill="none">
      <path d="M9.11992 8.1525C9.04492 8.145 8.95492 8.145 8.87242 8.1525C7.08742 8.0925 5.66992 6.63 5.66992 4.83C5.66992 2.9925 7.15492 1.5 8.99992 1.5C10.8374 1.5 12.3299 2.9925 12.3299 4.83C12.3224 6.63 10.9049 8.0925 9.11992 8.1525Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.37004 10.92C3.55504 12.135 3.55504 14.115 5.37004 15.3225C7.43254 16.7025 10.815 16.7025 12.8775 15.3225C14.6925 14.1075 14.6925 12.1275 12.8775 10.92C10.8225 9.5475 7.44004 9.5475 5.37004 10.92Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>,
  area:
    // prettier-ignore
    <svg className="w-4 md:w-4.5" viewBox="0 0 18 18" fill="none">
      <path d="M1.71729 5.83501V13.1325C1.71729 14.5575 2.72979 15.1425 3.95979 14.4375L5.72229 13.4325C6.10479 13.215 6.74229 13.1925 7.13979 13.395L11.0773 15.3675C11.4748 15.5625 12.1123 15.5475 12.4948 15.33L15.7423 13.47C16.1548 13.23 16.4998 12.645 16.4998 12.165V4.86751C16.4998 3.44251 15.4873 2.85751 14.2573 3.56251L12.4948 4.56751C12.1123 4.78501 11.4748 4.80751 11.0773 4.60501L7.13979 2.64001C6.74229 2.44501 6.10479 2.46001 5.72229 2.67751L2.47479 4.53751C2.05479 4.77751 1.71729 5.36251 1.71729 5.83501Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.41992 3V12.75" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.7974 4.965V15" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>,
};

export default OrderDeliveryInfo;
