import React, { useEffect, useRef, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import usePreventFirstRun from "../../hooks/usePreventFirstRun";
import Portal from "../../portal";
import { AppBtn } from "../buttons";
import Modal, { ModalBody, ModalFooter } from "../modal";
import SearchBar from "../search-bar";
import { DropdownOptionInterface } from "./select-dropdown";

interface Props
  extends Omit<React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>, "onBlur"> {
  name?: string;
  label: string;
  options: DropdownOptionInterface[];
  emptyLabel?: string;
  action?: {
    onClick: () => void;
    label: string;
  };
  onChange?: (e: any) => void;
  hasSearch?: Boolean;
  searchLabel?: string;
  error?: string;
  errors?: any;
  OptionRender?: React.FunctionComponent<{ option: DropdownOptionInterface; isOption?: boolean }>;
  onBlur?: VoidFunction | any;
  selectionRemovable?: boolean;
}

const SelectDropdownWithModal: React.FC<Props> = (props) => {
  const {
    label,
    options,
    value,
    className,
    action,
    emptyLabel,
    hasSearch,
    searchLabel,
    name,
    onChange,
    error,
    OptionRender,
    disabled,
    onBlur,
    selectionRemovable,
  } = props;
  const [selected, setSelected] = useState<any>(value);
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState(options);

  const selectedOption = [...options].find(({ value }) => value === selected);

  const selectItem = (value: any) => {
    setSelected(value);
    setOpen(false);

    if (onChange) {
      onChange({ target: { value, name, id: name } });
    }

    // if (onBlur) {
    //   onBlur();
    // }
  };

  useEffect(() => {
    if (hasSearch) {
      const results = [...options].filter(
        ({ text, value }) =>
          text.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
          value.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
      );

      setSearchResults(results);
      return;
    }

    setSearchResults(options);
  }, [searchText, options]);

  useEffect(() => {
    if (value !== selected) {
      setSelected(value);
    }
  });

  useEffect(() => {
    if (!open) {
      setSearchText("");
    }
  }, [open]);

  usePreventFirstRun(() => {
    if (!open && onBlur) {
      onBlur({ target: { name } });
    }
  }, [open]);

  // useClickOutside(dropdownRef, () => {
  //   setOpen(false);
  // });

  return (
    <div className={`mt-4 first:mt-0 ${className}`}>
      <div
        className={`h-12.5 w-full relative flex items-center font-action ${disabled ? "opacity-70" : ""}`}
        ref={dropdownRef}
      >
        <button
          className={`input-field h-full w-full !outline-none border rounded-lg text-1sm text-black-secondary px-4 sm:px-5 flex items-center justify-between ${
            error ? "border-accent-red-500" : selected ? "border-grey-divider" : "border-grey-divider"
          } ${open || selected ? "bg-white" : "bg-grey-fields-100 bg-opacity-40"} ${
            disabled ? "" : "focus:border-primary-500"
          }`}
          type="button"
          disabled={disabled}
          onClick={() => (disabled ? null : setOpen(!open))}
        >
          <label
            className={`text-sm text-placeholder absolute cursor-text pointer-events-none transform py-1 px-1.25 -translate-x-1.25  ${
              open || selected ? "bg-white top-0 -translate-y-1/2 text-xs leading-none" : ""
            }`}
          >
            {label}
          </label>

          {OptionRender && selectedOption && <OptionRender option={selectedOption} isOption={false} />}
          {selectedOption && !OptionRender && (
            <span className="text-black-secondary text-1sm">{selectedOption.text}</span>
          )}

          {/* prettier-ignore */}
          <svg width="18" viewBox="0 0 20 20" fill="none" className={`transition-transform ml-auto text-dark ${open ? "transform rotate-180" : ""} `}>
            <path d="M15 7.5L10 12.5L5 7.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      </div>
      {error && (
        <div className="text-accent-red-500 text-xs font-medium mt-1 flex items-center">
          {/* prettier-ignore */}
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="mr-1">
            <path d="M7.018 3.55288L2.15392 11.6731C2.05363 11.8467 2.00057 12.0437 2 12.2442C1.99944 12.4447 2.0514 12.6419 2.15071 12.8162C2.25003 12.9904 2.39323 13.1356 2.56607 13.2373C2.73892 13.339 2.93538 13.3937 3.13592 13.3959H12.8641C13.0646 13.3937 13.2611 13.339 13.4339 13.2373C13.6068 13.1356 13.75 12.9904 13.8493 12.8162C13.9486 12.6419 14.0006 12.4447 14 12.2442C13.9994 12.0437 13.9464 11.8467 13.8461 11.6731L8.982 3.55288C8.87963 3.3841 8.73548 3.24456 8.56347 3.14772C8.39146 3.05088 8.1974 3 8 3C7.8026 3 7.60854 3.05088 7.43653 3.14772C7.26452 3.24456 7.12037 3.3841 7.018 3.55288V3.55288Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 6.50452V8.8016" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 11.0992H8.00718" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="inline-block pt-1">{error}</span>
        </div>
      )}

      <Portal>
        <Modal size="midi" title={label} show={open} toggle={setOpen}>
          <ModalBody className="!pt-0">
            {hasSearch && (
              <div className="sticky top-0 pt-6.25 pb-2.5 bg-white z-[999]">
                <SearchBar placeholder={searchLabel} {...{ searchQuery: searchText, setSearchQuery: setSearchText }} />
              </div>
            )}

            {searchResults.length > 0 && (
              <ul className="flex flex-col divide-y divide-grey-divider">
                {searchResults.map((option, index) => (
                  <li
                    className="py-4 px-2.5 sm:px-3.75 flex items-start text-dark text-sm cursor-pointer hover:bg-grey-light w-full"
                    onClick={() => selectItem(option.value)}
                    key={index}
                  >
                    {OptionRender ? <OptionRender option={option} /> : option.text}
                    {selected === option.value && (
                      <>
                        {/* prettier-ignore */}
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" className="ml-auto text-success">
                          <rect width="18" height="18" rx="9" fill="currentColor"/>
                          <path d="M7.72136 12.375C7.54127 12.375 7.37019 12.2997 7.24412 12.1678L4.69585 9.50266C4.43472 9.22955 4.43472 8.77751 4.69585 8.5044C4.95698 8.2313 5.38919 8.2313 5.65033 8.5044L7.72136 10.6704L12.3497 5.82983C12.6108 5.55672 13.043 5.55672 13.3042 5.82983C13.5653 6.10294 13.5653 6.55498 13.3042 6.82809L8.1986 12.1678C8.07254 12.2997 7.90145 12.375 7.72136 12.375Z" fill="white"/>
                        </svg>
                      </>
                    )}
                  </li>
                ))}
              </ul>
            )}

            {searchResults.length < 1 && (
              <div className="py-5 px-2.5 text-placeholder text-center text-xs cursor-pointer">
                {emptyLabel || "No options to show"}
              </div>
            )}
          </ModalBody>
          {(action || selectionRemovable) && (
            <ModalFooter>
              {action && (
                <AppBtn
                  size="lg"
                  isBlock
                  onClick={() => {
                    action.onClick();
                    setOpen(false);
                  }}
                >
                  {action.label}
                </AppBtn>
              )}

              {selectionRemovable && (
                <AppBtn
                  size="lg"
                  isBlock
                  color="neutral"
                  className="text-accent-red-500"
                  onClick={() => selectItem("")}
                >
                  Remove Selection
                </AppBtn>
              )}
            </ModalFooter>
          )}
        </Modal>
      </Portal>
    </div>
  );
};

export default SelectDropdownWithModal;
