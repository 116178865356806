import { useEffect } from "react";

export function useAppendScript(url: string, id: string) {
  useEffect(() => {
    const script = document.getElementById(id);
    if (!script) {
      let scriptUrl = new URL(url);
      const el = document.createElement("script");
      el.src = scriptUrl.toString();
      el.id = id;
      document.body.appendChild(el);
    }
  }, []);
}
