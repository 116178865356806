import classNames from "classnames";
import React from "react";

type IconType = "edit" | "delete" | "link";

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  icon?: IconType;
  white?: boolean;
  grey?: boolean;
  smoke?: boolean;
}

const RoundActionButton: React.FC<IProps> = ({
  className,
  children,
  icon,
  white = false,
  grey = true,
  smoke = false,
  type = "button",
  ...props
}) => {
  const classes = classNames("flex items-center justify-center text-dark rounded-full w-9 h-9", className, {
    "bg-grey-fields-100": grey,
    "bg-white": white,
    "bg-grey-smoke": smoke,
  });
  return (
    <button className={classes} type={type} {...props}>
      {icon ? <Icons icon={icon} /> : children}
    </button>
  );
};

const Icons: React.FC<{ icon: IconType }> = ({ icon }) => {
  const icons = {
    edit:
      // prettier-ignore
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M3.69332 13.0137C3.28666 13.0137 2.90666 12.8737 2.63332 12.6137C2.28666 12.287 2.11999 11.7937 2.17999 11.2604L2.42666 9.10036C2.47332 8.69369 2.71999 8.15369 3.00666 7.86036L8.47999 2.06702C9.84666 0.620358 11.2733 0.580358 12.72 1.94703C14.1667 3.31369 14.2067 4.74036 12.84 6.18703L7.36666 11.9804C7.08666 12.2804 6.56666 12.5604 6.15999 12.627L4.01332 12.9937C3.89999 13.0004 3.79999 13.0137 3.69332 13.0137ZM10.62 1.94036C10.1067 1.94036 9.65999 2.26036 9.20666 2.74036L3.73332 8.54036C3.59999 8.68036 3.44666 9.01369 3.41999 9.20703L3.17332 11.367C3.14666 11.587 3.19999 11.767 3.31999 11.8804C3.43999 11.9937 3.61999 12.0337 3.83999 12.0004L5.98666 11.6337C6.17999 11.6004 6.49999 11.427 6.63332 11.287L12.1067 5.49369C12.9333 4.61369 13.2333 3.80036 12.0267 2.66703C11.4933 2.15369 11.0333 1.94036 10.62 1.94036Z" fill="currentColor" />
        <path d="M11.56 7.30023C11.5466 7.30023 11.5266 7.30023 11.5133 7.30023C9.4333 7.09356 7.75996 5.51356 7.43996 3.44689C7.39996 3.17356 7.58663 2.92023 7.85996 2.87356C8.1333 2.83356 8.38663 3.02023 8.4333 3.29356C8.68663 4.90689 9.9933 6.14689 11.62 6.30689C11.8933 6.33356 12.0933 6.58022 12.0666 6.85356C12.0333 7.10689 11.8133 7.30023 11.56 7.30023Z" fill="currentColor" />
        <path d="M14 15.167H2C1.72667 15.167 1.5 14.9403 1.5 14.667C1.5 14.3937 1.72667 14.167 2 14.167H14C14.2733 14.167 14.5 14.3937 14.5 14.667C14.5 14.9403 14.2733 15.167 14 15.167Z" fill="currentColor" />
      </svg>,
    delete:
      // prettier-ignore
      <svg width="16" height="16" viewBox="0 0 20 20" fill="none">
        <path d="M17.0142 5.45236C16.998 5.45236 16.9737 5.45236 16.9494 5.45236C12.6635 5.02296 8.38571 4.86093 4.14844 5.29032L2.49566 5.45236C2.15539 5.48477 1.85562 5.24171 1.82321 4.90144C1.7908 4.56116 2.03386 4.26949 2.36604 4.23708L4.01881 4.07505C8.329 3.63755 12.6959 3.80769 17.0709 4.23708C17.4031 4.26949 17.6461 4.56926 17.6137 4.90144C17.5894 5.21741 17.3221 5.45236 17.0142 5.45236Z" fill="currentColor" strokeWidth="1"/>
        <path d="M6.88754 4.63422C6.85513 4.63422 6.82272 4.63422 6.78221 4.62612C6.45814 4.56941 6.23129 4.25344 6.288 3.92936L6.46624 2.86802C6.59587 2.09024 6.77411 1.0127 8.66184 1.0127H10.7845C12.6804 1.0127 12.8586 2.13075 12.9801 2.87612L13.1584 3.92936C13.2151 4.26154 12.9882 4.57751 12.6642 4.62612C12.332 4.68283 12.016 4.45598 11.9674 4.13191L11.7892 3.07867C11.6757 2.37381 11.6514 2.23608 10.7926 2.23608H8.66994C7.81115 2.23608 7.79494 2.3495 7.67342 3.07057L7.48707 4.12381C7.43846 4.42358 7.1792 4.63422 6.88754 4.63422Z" fill="currentColor" strokeWidth="1"/>
        <path d="M12.3222 18.4319H7.12079C4.29325 18.4319 4.17982 16.8682 4.0907 15.6043L3.56408 7.44576C3.53977 7.11359 3.79903 6.82192 4.13121 6.79762C4.47149 6.78141 4.75505 7.03257 4.77936 7.36475L5.30598 15.5233C5.3951 16.7548 5.42751 17.2166 7.12079 17.2166H12.3222C14.0236 17.2166 14.056 16.7548 14.137 15.5233L14.6636 7.36475C14.6879 7.03257 14.9796 6.78141 15.3118 6.79762C15.6439 6.82192 15.9032 7.10549 15.8789 7.44576L15.3523 15.6043C15.2632 16.8682 15.1497 18.4319 12.3222 18.4319Z" fill="currentColor" strokeWidth="1"/>
        <path d="M11.0673 13.976H8.36936C8.03718 13.976 7.76172 13.7006 7.76172 13.3684C7.76172 13.0362 8.03718 12.7607 8.36936 12.7607H11.0673C11.3994 12.7607 11.6749 13.0362 11.6749 13.3684C11.6749 13.7006 11.3994 13.976 11.0673 13.976Z" fill="currentColor" strokeWidth="1"/>
        <path d="M11.7484 10.7348H7.69748C7.36531 10.7348 7.08984 10.4593 7.08984 10.1272C7.08984 9.79499 7.36531 9.51953 7.69748 9.51953H11.7484C12.0806 9.51953 12.356 9.79499 12.356 10.1272C12.356 10.4593 12.0806 10.7348 11.7484 10.7348Z" fill="currentColor" strokeWidth="1"/>
      </svg>,
  };

  return icons[icon];
};

export default RoundActionButton;
