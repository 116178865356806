import { useRequest } from "@/api/utils";
import { getFieldvalues } from "@/assets/js/utils/functions";
import Modal, { ModalBody, ModalFooter } from "@/components/ui/modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CURRENCIES, CouponItemInterface } from "@/assets/interfaces";
import { useEffect, useState } from "react";
import ErrorLabel from "@/components/ui/error-label";
import { InputField } from "@/components/ui/form-elements";
import { AppBtn } from "@/components/ui/buttons";
import { VerifyCouponsParams } from "@/api/interfaces/items.interface";
import { VerifyCoupon } from "@/api/items";

interface Props {
  show: boolean;
  toggle: (show: boolean) => void;
  applyCoupon: (coupon: CouponItemInterface) => void;
  store: string;
  itemsAmount: number;
  currency: CURRENCIES;
  customerId?: string;
}
export const ApplyCouponModal: React.FC<Props> = (props) => {
  const { show, toggle, applyCoupon, store, itemsAmount, currency, customerId = null } = props;
  const { makeRequest, response, isLoading } = useRequest<VerifyCouponsParams>(VerifyCoupon);
  const [error, setError] = useState(null);

  const form = useFormik({
    initialValues: { coupon_code: undefined },
    onSubmit: async (values) => {
      const key = btoa("CATLOG-USED-COUPONS");
      const storedCoupons = localStorage.getItem(key);
      const coupons: string[] = storedCoupons ? JSON.parse(atob(storedCoupons)) : [];

      if (coupons.includes(values.coupon_code.toUpperCase())) {
        setError("You have previously used this coupon code");
      } else {
        const [res, error] = await makeRequest({
          coupon_code: values.coupon_code,
          store,
          itemsAmount,
          currency,
          customer: customerId,
        });

        if (error) {
          setError(error?.message);
          return;
        }
        applyCoupon(res.data);
      }
    },
    validationSchema: Yup.object().shape({ coupon_code: Yup.string().required("Coupon code is required") }),
  });

  useEffect(() => {
    if (!show) {
      setError("");
      form.resetForm();
    }
  }, [show]);
  return (
    <Modal size="sm" show={show} toggle={toggle} title="Apply Coupon">
      <ErrorLabel error={error} perm={false} />
      <form onSubmit={form.handleSubmit} className="flex flex-col flex-auto overflow-hidden">
        <ModalBody>
          <InputField label="Enter coupon code" {...getFieldvalues("coupon_code", form)} />
        </ModalBody>
        <ModalFooter>
          <AppBtn type="submit" isBlock disabled={isLoading}>
            {isLoading ? "Checking Coupon..." : "Apply"}
          </AppBtn>
        </ModalFooter>
      </form>
    </Modal>
  );
};
